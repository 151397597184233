const React = require('react');
const { shape, string, number } = require('prop-types');
const { Image } = require('nordic/image');
const { Thumbnail } = require('@andes/thumbnail');

const namespace = 'ui-seller-data';

const ImageLogo = ({ logo }) => {
  const { width, url, action } = logo;

  return action ? (
    <a href={action.target}>
      <Thumbnail
        className={`${namespace}__logo-image`}
        modifier="square"
        size={width}
        srLabel={action.accessibility_text}
      >
        <Image alt="" key={url.src} src={url.src} />
      </Thumbnail>
    </a>
  ) : (
    <Thumbnail className={`${namespace}__logo-image`} modifier="square" size={width}>
      <Image alt="" key={url.src} src={url.src} />
    </Thumbnail>
  );
};

ImageLogo.propTypes = {
  logo: shape({
    width: number,
    url: shape({
      src: string,
    }),
    action: shape({
      target: string,
    }),
  }).isRequired,
};

module.exports = ImageLogo;
