const React = require('react');
const { shape, string, func } = require('prop-types');
const { StyledLabel } = require('@vpp-frontend-components/styled-label');
const { Icon } = require('@vpp-frontend-components/icon');
const FollowButton = require('./follow-button');

const namespace = 'ui-seller-data-header';

const MainInfo = ({ title, subtitle, followers, onFollowClick, ctaStatusInfo }) => (
  <div className={`${namespace}__main-info-container`}>
    <div className={`${namespace}__main-info`}>
      <div className={`${namespace}__title-container`}>
        <StyledLabel
          as="span"
          text={title.text}
          color={title.color}
          font_size={title.font_size}
          font_family={title.font_family}
          className={`${namespace}__title non-selectable`}
        />
      </div>
      {subtitle && (
        <div className={`${namespace}__subtitle-container`}>
          {subtitle.icon && <Icon id={subtitle.icon.id} className={`${namespace}__icon-verified`} />}
          <StyledLabel
            as="h3"
            text={subtitle.label.text}
            color={subtitle.label.color}
            font_size={subtitle.label.font_size}
            className={`${namespace}__subtitle`}
          />
        </div>
      )}
    </div>
    {followers && (
      <FollowButton
        label={ctaStatusInfo?.label_button}
        onFollowClick={onFollowClick}
        isFetching={followers.isFetching}
      />
    )}
  </div>
);

MainInfo.propTypes = {
  title: shape({
    text: string,
    color: string,
    font_size: string,
    font_family: string,
  }).isRequired,
  subtitle: shape({
    icon: shape({
      id: string,
    }),
    label: shape({
      text: string,
      color: string,
      font_size: string,
    }),
  }),
  followers: shape({}),
  onFollowClick: func,
  ctaStatusInfo: shape({}),
};

MainInfo.defaultProps = {
  subtitle: null,
  followers: null,
  onFollowClick: null,
  ctaStatusInfo: null,
};

module.exports = MainInfo;
