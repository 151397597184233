const React = require('react');
const { string, func, bool } = require('prop-types');
const { Button } = require('@andes/button');

const namespace = 'ui-seller-data-header';

const FollowButton = ({ label, onFollowClick, isFetching }) => {
  const handleClick = e => {
    e.preventDefault();
    onFollowClick(e);
  };

  return (
    <div className={`${namespace}__follow-action`}>
      <Button
        hierarchy="quiet"
        size="small"
        onClick={handleClick}
        disabled={isFetching}
        loading={isFetching}
        srLabel={label.accessibility_text}
      >
        {label.text}
      </Button>
    </div>
  );
};

FollowButton.propTypes = {
  label: string.isRequired,
  onFollowClick: func.isRequired,
  isFetching: bool,
};

FollowButton.defaultProps = {
  isFetching: null,
};

module.exports = FollowButton;
